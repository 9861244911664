@font-face {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url("WorkSans-VariableFont_wght.fe5ed19c.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Work Sans;
  font-style: italic;
  font-weight: 100 900;
  font-display: block;
  src: url("WorkSans-Italic-VariableFont_wght.403c1dfa.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=index.8c554eaf.css.map */
